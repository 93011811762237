import PauseIcon from "@mui/icons-material/Pause";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export const statuses = {
    SUCCESS: "SUCCESS",
    LOADING: "LOADING",
    ERROR: "ERROR"
};

export const WorkflowStatusIcons = {
    active: <PlayArrowIcon color="secondary" />,
    paused: <PauseIcon color="primary" />,
    finished: <DoneOutlineIcon color="success" />,
};
