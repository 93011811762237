import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../../components/common";

const initialState = {
    allClients: {},
    clientsStatus: initialStatus,
    fieldValues: {
        billingStatus: [],
        monitoringSiteId: [],
        sequences: [],
        salesRep: [],
        states: [],
        tags: [],
    },
    clientDetails: null,
    clientStatus: initialStatus,
    letters: {},
    lettersStatus: initialStatus,
    faxesList: {},
    faxesListStatus: initialStatus,
    furnisherEdit: null,
    bureauEdit: null,
    clientNote: {},
    clientNoteStatus: initialStatus,
    clientActivity: {},
    clientActivityStatus: initialStatus,
    clientTask: {},
    clientTaskStatus: initialStatus,
    taskSuggetionData: [],
    clientActionPlan: {},
    clientActionPlanStatus: initialStatus,
    clientReview: {},
    clientReviewStatus: initialStatus,
    clientDocument: {},
    clientDocumentStatus: initialStatus,
    clientAgreement: {},
    clientAgreementStatus: initialStatus,
    items: {},
    itemsStatus: initialStatus,
    item: {},
    itemStatus: initialStatus,
    itemDetails: null,
    itemDetailsStatus: initialStatus,
    emails: {},
    sms: {},
    emailsStatus: initialStatus,
    smsStatus: initialStatus,
    systemCustomerTemplate: null,
    templateSnippitsList: null,
    templatePreview: [],
    smsTemplateList: null,
    clientSegmentList: {},
    clientSegmentListStatus: initialStatus,
    noteCategories: null,
    noteTemplates: null,
    financeGoals: null,
};

const reducers = {
    setAllClient(state, action) {
        state.allClients = action.payload;
    },
    setAllClientStatus(state, action) {
        state.clientsStatus = action.payload;
    },
    setFieldValues(state, action) {
        const { results, type } = action.payload;

        // Mapping of action types to corresponding field keys in the state
        const fieldMapping = {
            billing_status_list: "billingStatus",
            monitoring_site_listing: "monitoringSiteId",
            sequence_list: "sequences",
            users: "salesRep",
            states: "states",
            tags: "tags",
        };

        // Function to map results based on the action type
        const mapResults = (type, element) => {
            switch (type) {
                case "states":
                    // For "states", include additional "id" field
                    return { name: element.code, label: element.name, id: element.id };
                case "users":
                    // For "users", combine first and last names for the label
                    return { name: element.id, label: `${element.first_name} ${element.last_name}` };
                case "tags":
                    // For "tags", use the "tag" property as the label
                    return { name: element.id, label: element.tag };
                default:
                    // Default mapping for most types
                    return { name: element.id, label: element.name };
            }
        };

        // If the action type matches a key in the mapping, process the results
        if (fieldMapping[type]) {
            const mappedField = fieldMapping[type];
            state.fieldValues[mappedField] = results.map((element) => mapResults(type, element));
        }
    },
    setClientDetails(state, action) {
        state.clientDetails = action.payload;
    },
    setClientStatus(state, action) {
        state.clientStatus = action.payload;
    },
    setLetters(state, action) {
        state.letters = action.payload;
    },
    setLettersStatus(state, action) {
        state.lettersStatus = action.payload;
    },
    setFaxesList: (state, action) => {
        const { payload } = action;
        state.faxesList = payload;
    },
    setFaxesListStatus: (state, action) => {
        const { payload } = action;
        state.faxesListStatus = payload;
    },
    setFurnisherEdit: (state, action) => {
        const { payload } = action;
        state.furnisherEdit = payload;
    },
    setBureauEdit: (state, action) => {
        const { payload } = action;
        state.bureauEdit = payload;
    },
    setClientNote(state, action) {
        state.clientNote = action.payload;
    },
    setClientNoteStatus(state, action) {
        state.clientNoteStatus = action.payload;
    },
    setClientActivity(state, action) {
        state.clientActivity = action.payload;
    },
    setClientActivityStatus(state, action) {
        state.clientActivityStatus = action.payload;
    },
    setClientTask(state, action) {
        state.clientTask = action.payload;
    },
    setClientTaskStatus(state, action) {
        state.clientTaskStatus = action.payload;
    },
    setTaskSuggetion(state, action) {
        state.taskSuggetionData = action.payload;
    },
    setClientActionPlan(state, action) {
        state.clientActionPlan = action.payload;
    },
    setClientActionPlanStatus(state, action) {
        state.clientActionPlanStatus = action.payload;
    },
    setClientReview(state, action) {
        state.clientReview = action.payload;
    },
    setClientReviewStatus(state, action) {
        state.clientReviewStatus = action.payload;
    },
    setClientDocument(state, action) {
        state.clientDocument = action.payload;
    },
    setClientDocumentStatus(state, action) {
        state.clientDocumentStatus = action.payload;
    },
    setClientAgreement(state, action) {
        state.clientAgreement = action.payload;
    },
    setClientAgreementStatus(state, action) {
        state.clientAgreementStatus = action.payload;
    },
    setItems(state, action) {
        state.items = action.payload;
    },
    setItemsStatus(state, action) {
        state.itemsStatus = action.payload;
    },
    setItem(state, action) {
        state.item = action.payload;
    },
    setItemStatus(state, action) {
        state.itemStatus = action.payload;
    },
    setItemDetails(state, action) {
        state.itemDetails = action.payload;
    },
    setItemDetailsStatus(state, action) {
        state.itemDetailsStatus = action.payload;
    },
    setEmails(state, action) {
        state.emails = action.payload;
    },
    setEmailsStatus(state, action) {
        state.emailsStatus = action.payload;
    },
    setSms(state, action) {
        state.sms = action.payload;
    },
    setSmsStatus(state, action) {
        state.smsStatus = action.payload;
    },
    setSystemCustomerTemplateList(state, action) {
        const { payload } = action;
        state.systemCustomerTemplate = payload;
    },
    setTemplateSnippitsList(state, action) {
        const { payload } = action;
        state.templateSnippitsList = payload;
    },
    setTemplatePreview(state, action) {
        const { payload } = action;
        state.templatePreview = payload;
    },
    setSmsTemplateList(state, action) {
        const { payload } = action;
        state.smsTemplateList = payload;
    },
    setClientSegmentList(state, action) {
        const { payload } = action;
        state.clientSegmentList = payload;
    },
    setClientSegmentListStatus(state, action) {
        const { payload } = action;
        state.clientSegmentListStatus = payload;
    },
    setNoteCategories(state, action) {
        const { payload } = action;
        state.noteCategories = payload;
    },
    setNoteTemplates(state, action) {
        const { payload } = action;
        state.noteTemplates = payload;
    },
    setFinanceGoals(state, action) {
        const { payload } = action;
        state.financeGoals = payload;
    },
};

const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers,
});

export const {
    setAllClient,
    setAllClientStatus,
    setFilter,
    setFieldValues,
    setLetters,
    setLettersStatus,
    setFaxesList,
    setFaxesListStatus,
    setFurnisherEdit,
    setBureauEdit,
    setClientNote,
    setClientNoteStatus,
    setClientActivity,
    setClientActivityStatus,
    setClientTask,
    setClientTaskStatus,
    setTaskSuggetion,
    setClientActionPlan,
    setClientActionPlanStatus,
    setClientReview,
    setClientReviewStatus,
    setClientDocument,
    setClientDocumentStatus,
    setClientAgreement,
    setClientAgreementStatus,
    setEmails,
    setSms,
    setClientDetails,
    setClientStatus,
    setItems,
    setItemsStatus,
    setItem,
    setItemStatus,
    setItemDetails,
    setItemDetailsStatus,
    setEmailsStatus,
    setSmsStatus,
    setSystemCustomerTemplateList,
    setTemplateSnippitsList,
    setTemplatePreview,
    setSmsTemplateList,
    setClientSegmentList,
    setClientSegmentListStatus,
    setNoteCategories,
    setNoteTemplates,
    setFinanceGoals,
} = clientSlice.actions;
export default clientSlice.reducer;
