import { combineReducers } from "@reduxjs/toolkit";
import clientsReducer from "../slices/client";
import clientReducer from "../features/client/clientSlice";
import filterReducer from "../slices/advanceFilter";
import itemReducer from "../slices/item";
import tabsReducer from "../slices/tabs";
import settingReducer from "../slices/settings";
import workFlowReducer from "../slices/workflows";
import segmentReducer from "../slices/segment";
import systemReducer from "../slices/system";
import taskReducer from "../slices/task";
import templateReducer from "../slices/template";
import billingSetupReducer from "../slices/billingSetup";
import ActivityReducer from "../slices/activity";

import userManagementReducer from "../features/userManagement/userManagementSlice";

export const rootReducer = combineReducers({
  clients: clientsReducer,
  client: clientReducer,
  advanceFilter: filterReducer,
  items: itemReducer,
  settings: settingReducer,
  workflows: workFlowReducer,
  segments: segmentReducer,
  system: systemReducer,
  task: taskReducer,
  template: templateReducer,
  tabs: tabsReducer,
  billingSetup: billingSetupReducer,
  userManagement: userManagementReducer,
  activity: ActivityReducer,
});
