import Router from "next/router";
import { get, post } from "../../helpers/api/api_helper";
import axios from "axios";
import { paths } from "../../data/paths";

// Get the API base URL from environment variables
const API_URL = process.env.API_URL;

async function login(request) {
  const response = await axios.post(`${API_URL}/login`, request);
  return response.data
}

async function refresh(request) {
  return await post("/refresh", request, {});
}

async function sendOtp(request) {
  const response = await axios.post(`${API_URL}/request_otp`, request)
  return response.data
}

async function verifyOtp(request) {
  const response = await axios.post(`${API_URL}/verify_otp`, request)
  return response.data
}

async function sendResetLink(request) {
  const response = await axios.post(`${API_URL}/forgot_password`, request);
  return response.data
}
async function forgotPassword(request) {
  const response = await axios.post(`${API_URL}/update_forget_password`, request);
  return response.data
}

async function resetPassword(request) {
  const response = await axios.post(`${API_URL}/resetPassword`, request);
  return response.data
}

async function updateProfile(request) {
  return await post("/profile", request, {});
}

async function getProfile() {
  return await get("/profile", {});
}

async function logout(request = {}) {
  try {
    const res = await post("/logout", request, {});
    if (res.code === 200) {
      localStorage.clear();
      sessionStorage.clear();
      Router.push(paths.index.path);
    }
  } catch (error) {
    localStorage.clear();
    console.log(error)
  }
}

export const authApiService = {
  login,
  logout,
  refresh,
  sendResetLink,
  forgotPassword,
  resetPassword,
  updateProfile,
  getProfile,
  sendOtp,
  verifyOtp,
};